<!-- 心理咨询配置 -- 咨询室设置 -- 新增 -->
<template>
	<edit-template @confirm="submit" @cancel="$confirm('取消新增?','提示',{type:'warning'}).then(res=>$router.back());" :cancelRemind="false" confirmText="保存" cancelText="取消">
		
    <div class="form-body" style="padding-top: 70rem">
      <el-form ref="form" :model="formData" label-width="150rem" :rules="rules">
        <div>
          <el-form-item label="心理咨询室名称" prop="chamber_name">
            <el-input placeholder="请输入" maxlength="50" v-model="formData.chamber_name"></el-input>
          </el-form-item>
          <el-form-item label="校区" prop="school_id">
            <el-select :popper-append-to-body="false" v-model="formData.school_id" @change="schoolChange">
              <el-option v-for="item in schoolConfig" :key="item.id" :label="item.school_name" :value="item.id">
            </el-option></el-select>
          </el-form-item>
          <el-form-item label="楼宇" prop="building_id">
            <el-select :popper-append-to-body="false" v-model="formData.building_id" @change="buildChange">
              <el-option v-for="item in buildConfig" :key="item.id" :label="item.building_name" :value="item.id">
            </el-option></el-select>
          </el-form-item>
          <el-form-item label="楼层" prop="floor">
            <el-select :popper-append-to-body="false" v-model="formData.floor" @change="floorChange">
              <el-option v-for="item in floorConfig" :key="item.level" :label="item.level" :value="item.level">
            </el-option></el-select>
          </el-form-item>
          <el-form-item label="房间名称" prop="chamber_id">
            <el-select :popper-append-to-body="false" v-model="formData.chamber_id">
              <el-option v-for="item in roomConfig" :key="item.id" :label="item.room_name" :value="item.id">
            </el-option></el-select>
          </el-form-item>
          <el-form-item label="房间编号" prop="chamber_id">
            <el-select :popper-append-to-body="false" v-model="formData.chamber_id">
              <el-option v-for="item in roomConfig" :key="item.id" :label="item.room_num" :value="item.id">
            </el-option></el-select>
          </el-form-item>
          <el-form-item label="是否启用" required="">
            <el-switch v-model="formData.status" active-color="#1d2088" inactive-color="rgba(0,0,0,.2)">
            </el-switch>
          </el-form-item>

        </div>
      </el-form>
    </div>
    
  
</edit-template>
</template>

<script>
export default {
	_config:{"route":{"path":"add","meta":{"title":"新增"}}},
  data() {
    return {
      allConfig: [],
      schoolConfig: [],  // 校区配置
      schoolIndex: '',  //当前school选中的index
      buildConfig: [],  // 楼宇配置
      buildIndex: '',
      floorConfig: [],  // 楼层配置
      roomConfig: [],  // 房间配置

      // 表单数据
      formData: {
        chamber_name: '',
        school_id: '',
        building_id: '',
        floor: '',
        chamber_id: '',
        status: true,
      },
      // 表单验证规则
      rules: {
        chamber_name: [{required: true, message: '请输入心理咨询室名称', trigger: 'change'}],
        school_id: [{required: true, message: '请选择校区', trigger: 'change'}],
        building_id: [{required: true, message: '请选择楼宇', trigger: 'change'}],
        floor: [{required: true, message: '请选择楼层', trigger: 'change'}],
        chamber_id: [{required: true, message: '请选择房间', trigger: 'change'}],
      },
    }
  },
  created() {
    this.getSchoolConfig()

  },
  methods: {
    getSchoolConfig() {
      this.$_axios2.get('api/evaluation/counseling-setting/chamber-tree').then(res => {
        let data = res.data.data;
        if (data.length) {
          this.allConfig = data;
          this.schoolConfig = data
        }
      })
    },

    schoolChange(e){
      console.log(e)
      this.buildConfig = [];
      this.formData.building_id = '';
      this.floorConfig = [];
      this.formData.floor = '';
      this.roomConfig = [];
      this.formData.chamber_id = '';
      this.allConfig.forEach((item, index) => {
        if(item.id === e){
          this.schoolIndex = index;
          this.buildConfig = item.building
        }
      })
    },

    buildChange(e){
      this.floorConfig = [];
      this.formData.floor = '';
      this.roomConfig = [];
      this.formData.chamber_id = '';
      this.allConfig[this.schoolIndex].building.forEach((item, index) => {
        if(item.id === e){
          this.buildIndex = index;
          this.floorConfig = item.floor
        }
      })
    },

    floorChange(e){
      this.roomConfig = [];
      this.formData.chamber_id = '';
      this.allConfig[this.schoolIndex].building[this.buildIndex].floor.forEach((item) => {
        if(item.level === e){
          this.roomConfig = item.room
        }
      })
    },

    // 表单提交
    submit() {
      console.log(this.formData)
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$_axios2.post('api/evaluation/counseling-setting/chamber', {...this.formData}).then(res => {
            if (res.data.status === 0) {
              this.$message.success({
                message: '新增成功',
                onClose: () => {
                  this.$store.commit("setPage", 1);
                  this.$router.go(-1)
                }
              })
            }
          })
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-form .el-input {
  width: 250rem !important;
}

::v-deep .yyy .el-form-item__label {
  line-height: 72rem !important;
}

::v-deep .el-form .yyy2 .el-input {
  width: 150rem !important;
}

.yyy {
  overflow: hidden;
}

.yyy1 .el-form-item {
  margin-bottom: 0;
}


.knowledge {
  margin-bottom: 24rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .knowledge_box {
    display: flex;
    align-items: center;
    height: 72rem;
    padding: 0 40rem;
    background-color: #f4f4f4;
  }

  .add_knowledge {
    display: flex;
    padding-left: 40rem;
  }
}
</style>
